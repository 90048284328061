import { Injectable } from '@angular/core';

@Injectable()
export class GlobalService {
    public urlApi: string;

    public empresaNombre: string;
    public empresaTlf1: string;
    public empresaTlf2: string;
    public empresaEmail1: string;
    public empresaEmail2: string;
    public empresaDireccion: string;
    public empresaAnnoFundada: number;
    public empresaAnnosExperiencia: number;
    public webAnnoFundada: number;
    public webAnnoCopyright: string;

    constructor() {
        const produccion = 1;

        if (produccion) {
            this.urlApi = 'http://172.81.133.235:3789/api/';
        } else {
            this.urlApi = 'http://localhost:3789/api/';
        }

        this.empresaNombre = 'Forma Producciones Audiovisuales';
        this.empresaTlf1 = '+51 01 2561244';
        this.empresaTlf2 = '+51 01 2561260';
        this.empresaEmail1 = 'imagen@formaproducciones.com';
        this.empresaEmail2 = 'marketing@formaproducciones.com';
        this.empresaDireccion = 'Urb. San Ignacio de Monterrico, Calle Cerro Prieto nro. 429, Santiago de Surco, Lima, Lima';
        this.empresaAnnoFundada = 1995;
        this.empresaAnnosExperiencia = (new Date()).getFullYear() - this.empresaAnnoFundada;

        this.webAnnoFundada = 2018;

        if ((new Date()).getFullYear() === this.webAnnoFundada) {
            this.webAnnoCopyright = '' + this.webAnnoFundada;
        } else {
            this.webAnnoCopyright = this.webAnnoFundada + ' - ' + (new Date()).getFullYear();
        }
    }

    getUrl() {
        return this.urlApi;
    }

    loading(opcion) {
        if (opcion) {
            $('#cargandoFN').css('display', '');
        } else {
            $('#cargandoFN').css('display', 'none');
        }
    }

    mensajeNotificacion(plugin, tipo, titulo, mensaje, autoClose = 1) {
        switch (plugin.toLowerCase()) {
            case 'toastr':
                toastr.options.closeButton = true;
                toastr.options.progressBar = true;
                toastr.options.positionClass = 'toast-bottom-right';

                if (autoClose) {
                    toastr.options.timeOut = 5000;
                    toastr.options.extendedTimeOut = 2000;
                } else {
                    toastr.options.timeOut = 0;
                    toastr.options.extendedTimeOut = 0;
                }

                switch (tipo.toLowerCase()) {
                    case 'success':
                        toastr.success(mensaje, titulo);
                        break;

                    case 'warning':
                        toastr.warning(mensaje, titulo);
                        break;

                    case 'error':
                        toastr.error(mensaje, titulo);
                        break;

                    case 'info':
                        toastr.info(mensaje, titulo);
                        break;
                }
                break;

            case 'swal':
                swal({ title: titulo, text: mensaje, type: tipo });
                break;
        }
    }

    parametrizarError(error: any) {
        const erroMessage = <any>error;

        if (erroMessage !== null) {
            if (erroMessage._body) {
                const body = JSON.parse(erroMessage._body);

                if (body.authenticatedError === 1) {
                    swal({
                        title: 'Disculpe!',
                        text: 'Su sesion ha expirado',
                        type: 'info',
                        showCancelButton: false,
                        confirmButtonClass: 'btn btn-success',
                        buttonsStyling: false,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'Aceptar'
                    }).then(() => {
                        localStorage.clear();
                    }).catch(swal.noop);
                } else {
                    if (body.message) {
                        this.mensajeNotificacion('swal', 'error', 'Error!', body.message);
                    } else {
                        this.mensajeNotificacion('swal', 'error', 'Error!', 'Disculpe, ocurrio un error en el servidor!');
                    }
                }
            } else {
                this.mensajeNotificacion('swal', 'error', 'Error!', 'Disculpe, ocurrio un error en el servidor!');
            }
        }
    }

    cargarFuncionalidades() {
        /*------------- preloader js --------------*/
        $(window).on('load', function () { // makes sure the whole site is loaded
            $('.preloder-wrap').fadeOut(); // will first fade out the loading animation
            $('.loader').delay(150).fadeOut('slow'); // will fade out the white DIV that covers the website.
            $('body').delay(150).css({
                'overflow': 'visible'
            });
        });

        // search
        $('.search-wrap ul li a').on('click', function () {
            $('.search-area').addClass('active');
        });

        $('.search-area span.closs-btn ').on('click', function () {
            $('.search-area').removeClass('active');
        });

        // stickey menu
        $(window).on('scroll', function () {
            const scroll = $(window).scrollTop(),
                mainHeader = $('#sticky-header'),
                mainHeaderHeight = mainHeader.innerHeight();

            // console.log(mainHeader.innerHeight());
            if (scroll > 2) {
                $('#sticky-header').addClass('sticky');
            } else {
                $('#sticky-header').removeClass('sticky');
            }
        });

        // slicknav
        $('ul#navigation').slicknav({
            prependTo: '.responsive-menu-wrap'
        });

        // slider-active
        $('.slider-active3').owlCarousel({
            smartSpeed: 1000,
            margin: 0,
            nav: true,
            autoplay: true,
            autoplayTimeout: 10000,
            loop: true,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1
                },
                450: {
                    items: 1
                },
                678: {
                    items: 1
                },
                1000: {
                    items: 1
                }
            }
        });

        // slider-active
        $('.slider-active3').on('translate.owl.carousel', function () {
            $('.slider-content h2').removeClass('fadeInUp animated').hide();
            $('.slider-content p').removeClass('fadeInUp animated').hide();
            $('.slider-content ul').removeClass('fadeInUp animated').hide();
        });

        $('.slider-active3').on('translated.owl.carousel', function () {
            $('.owl-item.active .slider-content h2').addClass('fadeInUp animated').show();
            $('.owl-item.active .slider-content p').addClass('slideInUp animated').show();
            $('.owl-item.active .slider-content ul').addClass('fadeInUp animated').show();
        });

        /*==========================================================================
            WHEN DOCUMENT LOADING
        ==========================================================================*/

        // // stickey menu
        $(window).on('scroll', function () {
            const scroll = $(window).scrollTop(),
                mainHeader = $('#sticky-header'),
                mainHeaderHeight = mainHeader.innerHeight();

            // console.log(mainHeader.innerHeight());
            if (scroll > 1) {
                $('#sticky-header').addClass('sticky');
            } else {
                $('#sticky-header').removeClass('sticky');
            }
        });

        // hover effect
        $('.portfolio').snakeify({
            speed: 300
        });

        $('.portfolio2').snakeify({
            speed: 300
        });

        $('.team-wrap2 .team-img').snakeify({
            speed: 300
        });

        /*--------------------------
        scrollUp
        ---------------------------- */
        $.scrollUp({
            scrollText: '<i class="fa fa-arrow-up"></i>',
            easingType: 'linear',
            scrollSpeed: 900,
            animation: 'fade'
        });

        /*--
        Magnific Popup
        ------------------------*/
        $('.popup').magnificPopup({
            type: 'image',
            gallery: {
                enabled: true
            }

        });

        /* magnificPopup video view */
        $('.video-popup').magnificPopup({
            type: 'iframe',
            gallery: {
                enabled: true
            }
        });

        // counter up
        /* $('.counter').counterUp({
            delay: 10,
            time: 1000
        }); */

        /*-------------------------------------------------------
            blog details
        -----------------------------------------------------*/
        if ($('.about-area,.featured-area,.skill-area').length) {
            const post = $('.about-area .about-img, .featured-img,.skill-area .skill-img');

            post.each(function () {
                const $this = $(this);
                const entryMedia = $this.find('img');
                const entryMediaPic = entryMedia.attr('src');

                $this.css({
                    backgroundImage: 'url(' + entryMediaPic + ')',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                });
            });
        }

        // test-active
        $('.test-active').owlCarousel({
            smartSpeed: 1000,
            margin: 0,
            nav: false,
            autoplay: true,
            autoplayTimeout: 6000,
            loop: true,
            slideBy: 2,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1,
                    slideBy: 1
                },
                450: {
                    items: 1,
                    slideBy: 1
                },
                678: {
                    items: 2
                },
                1000: {
                    items: 2
                }
            }
        });

        // pricing-active
        $('.pricing-active').owlCarousel({
            smartSpeed: 1000,
            margin: 0,
            nav: true,
            loop: true,
            slideBy: 2,
            navText: ['<i class="fa fa-angle-right"></i>', '<i class="fa fa-angle-left"></i>'],
            responsive: {
                0: {
                    items: 1,
                },
                450: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                800: {
                    items: 3,
                },
                1000: {
                    items: 3
                }
            }
        });

        // pricing-active
        $('.single-service-active').owlCarousel({
            smartSpeed: 1000,
            margin: 0,
            nav: true,
            loop: true,
            slideBy: 2,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1,
                },
                450: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                800: {
                    items: 1,
                },
                1000: {
                    items: 1
                }
            }
        });

        $('.grid').imagesLoaded(function () {

            // filter items on button click
            $('.portfolio-menu').on('click', 'button', function () {
                const filterValue = $(this).attr('data-filter');
                $grid.isotope({
                    filter: filterValue
                });
            });

            // init Isotope
            const $grid = $('.grid').isotope({
                itemSelector: '.portfolio',
                percentPosition: true,
                masonry: {
                    // use outer width of grid-sizer for columnWidth
                    columnWidth: '.portfolio',
                }
            });
        });

        $('.portfolio-menu button').on('click', function (event) {
            $(this).siblings('.active').removeClass('active');
            $(this).addClass('active');
            event.preventDefault();
        });

        // brand-active
        $('.brand-active').owlCarousel({
            margin: 0,
            loop: true,
            autoplay: true,
            autoplayTimeout: 4000,
            nav: false,
            smartSpeed: 800,
            navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
            URLhashListener: true,
            startPosition: 'URLHash',
            responsive: {
                0: {
                    items: 2
                },
                450: {
                    items: 2
                },
                768: {
                    items: 4
                },
                1000: {
                    items: 5
                }
            }
        });

        /*------------------------------------------
            Pricing table switcher
        -------------------------------------------*/
        if ($('.pricing-switcher-wrap').length) {
            const switcher = $('.pricing-switcher-wrap .pricing-switch');
            const pricingTab = $('.pricing-table');
            const tablist = $('.pricing-switcher-wrap .pricing-tablist');


            switcher.on('click', function () {
                const $this = $(this);
                $this.find('.pricing-slider').toggleClass('pricing-slide-off');
                pricingTab.children('.pricing-grids').toggleClass('active-price');
                tablist.children('span').toggleClass('active');
            });
        }

        if ($('.slider-area .slider-items').length) {
            $('.slider-area .slider-items').each(function () {
                const $this = $(this);
                const img = $this.find('.slider').attr('src');

                $this.css({
                    backgroundImage: 'url(' + img + ')',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center'
                });
            });
        }
    }
}
