import { Component, OnInit, DoCheck, AfterViewInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params, RouterModule } from '@angular/router';

// Servicios
import { GlobalService } from '../../../../services/global.service';
import { ScriptService } from '../../../../services/script.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [
  ]
})
export class HeaderComponent implements OnInit/* , DoCheck */, AfterViewInit {
  public menu;
  public loading;
  public breadcrumb;
  public identity;
  public urlApi: string;
  public menucontainer;

  constructor(
    private _router: Router,
    private _scriptService: ScriptService,
    public _globalService: GlobalService
  ) {
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this._scriptService.load('slicknav_btn').then(data => {
      $('ul#navigation').slicknav({
        prependTo: '.responsive-menu-wrap'
      });

      $('.slicknav_btn').attr('href', 'javascript:void(0);');
    }).catch(error => console.log(error));
  }
}
