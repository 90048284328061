import { Component, OnInit, ElementRef, AfterViewInit, ViewChild } from '@angular/core';

// Modulos

// Servicios
import { GlobalService } from '../../services/global.service';

// Modelos

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: [
    './main.component.css',
  ],
  providers: [
  ]
})
export class MainComponent implements OnInit, AfterViewInit {

  constructor(
    private _elementRef: ElementRef,
    public _globalService: GlobalService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this._globalService.cargarFuncionalidades();

    this.filterSelection('colegios'); // Execute the and show all columns

    // Add active class to the current button (highlight it)

    const btns = document.getElementsByClassName('btns');
    for (let i = 0; i < btns.length; i++) {
      btns[i].addEventListener('click', function () {
        const current = document.getElementsByClassName('active');
        current[0].className = current[0].className.replace(' active', '');
        this.className += ' active';
      });
    }

    $('.modalImagenClose').click(function () {
      $('#modalImagen').css('display', 'none');
    });

    /* $('.counter').counterUp({
      delay: 10,
      time: 1000
    }); */
  }

  showModal(event) {
    $('#modalImagen').css('display', 'block');
    $('#img01').attr('src', $(event.target.children[0]).attr('src'));
    $('#modalImagenCaption').html($(event.target.children[0]).attr('alt'));
  }

  filterSelection(c) {
    let x, i;
    x = document.getElementsByClassName('column');
    if (c === 'all') {
      c = '';
    }
    // Add the 'show' class (display:block) to the filtered elements, and remove the 'show' class from the elements that are not selected
    for (i = 0; i < x.length; i++) {
      this.w3RemoveClass(x[i], 'show');
      if (x[i].className.indexOf(c) > -1) {
        this.w3AddClass(x[i], 'show');
      }
    }
  }

  // Show filtered elements
  w3AddClass(element, name) {
    let i, arr1, arr2;
    arr1 = element.className.split(' ');
    arr2 = name.split(' ');
    for (i = 0; i < arr2.length; i++) {
      if (arr1.indexOf(arr2[i]) === -1) {
        element.className += ' ' + arr2[i];
      }
    }
  }

  // Hide elements that are not selected
  w3RemoveClass(element, name) {
    let i, arr1, arr2;
    arr1 = element.className.split(' ');
    arr2 = name.split(' ');
    for (i = 0; i < arr2.length; i++) {
      while (arr1.indexOf(arr2[i]) > -1) {
        arr1.splice(arr1.indexOf(arr2[i]), 1);
      }
    }
    element.className = arr1.join(' ');
  }
}
