import { Component, OnInit, DoCheck } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

// Servicios
import { ScriptStore } from './services/script.store';

// Modelos

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  // styleUrls: ['./app.component.css'],
  providers: [
  ]
})
export class AppComponent implements OnInit {
  private scripts: any = {};

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
  ) {
  }

  ngOnInit() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });

    localStorage.setItem('scripts', JSON.stringify(this.scripts));
  }
}
