import { Component, OnInit, DoCheck, AfterViewInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

// Servicios

@Component({
  selector: 'app-menu-principal',
  templateUrl: './menu-principal.component.html',
  styleUrls: ['./menu-principal.component.css'],
  providers: [
  ]
})
export class MenuPrincipalComponent implements OnInit/* , DoCheck */, AfterViewInit {
  public menu;
  public loading;
  public breadcrumb;
  public identity;
  public urlApi: string;
  public menucontainer;
  public buildSrcImg;
  public tour;

  constructor(
    private _elementRef: ElementRef,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }
}
