import { Component, OnInit, AfterViewInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-flipbook',
  templateUrl: './flipbook.component.html',
  styleUrls: ['./flipbook.component.css']
})
export class FlipbookComponent implements OnInit, AfterViewInit {
  @Input() titulo: string;
  @Input() ruta: string;
  @Input() totalPage: number;
  @Input() altoImg: number;
  @Input() anchoImg: number;
  @Output() parametros = new EventEmitter();

  public numbersArray = [];
  public paginaActual;
  public observador;

  constructor(
    private _elementRef: ElementRef
  ) {
  }

  ngOnInit() {
    this.paginaActual = 1;

    for (let index = 0; index < this.totalPage; index++) {
      this.numbersArray.push(index + 1);
    }
  }

  ngAfterViewInit() {
    const anchoImg = this.anchoImg * 2;
    const altoImg = this.altoImg;

    const altoVentana = $(window).height();
    const anchoVentana = $(window).width();

    let anchoBook;
    let altoBook;
    let porcetaje;
    let mitadAncho;
    let mitadAlto;
    let posiAncho;
    let posiAlto;

    altoBook = altoVentana * 0.90 - 100;
    porcetaje = anchoImg * 100 / altoImg;
    anchoBook = altoBook * porcetaje / 100;

    if (anchoBook > anchoVentana) {
      anchoBook = anchoVentana * 0.90;
      porcetaje = altoImg * 100 / anchoImg;
      altoBook = anchoBook * porcetaje / 100;
    }

    mitadAncho = anchoVentana / 2;
    mitadAlto = altoVentana / 2;

    posiAncho = anchoVentana / 2 - anchoBook / 2;
    posiAlto = altoVentana / 2 - altoBook / 2;

    $('#flipbook').turn({
      width: anchoBook,
      height: altoBook,
      elevation: 50,
      gradients: true,
      autoCenter: true,
      duration: 1000
    });

    $('#flipbook').bind('turning', (event, page, view) => {
      if (view[1] === 0) {
        this.paginaActual = this.totalPage;
      } else {
        this.paginaActual = view[1];
      }

      $('#flipbook').css('margin-left', posiAncho);
    });

    $('#flipbookContainer').css('width', anchoBook);
    $('#flipbookContainer').css('left', '50%');
    $('#flipbookContainer').css('margin', 'auto');

    $('#flipbookPopupConten').css('margin-top', posiAlto);
  }

  prevPage() {
    $('#flipbook').turn('previous');
  }

  nextPage() {
    $('#flipbook').turn('next');
  }

  close() {
    this.parametros.emit({
      'closeFlipbook': 1
    });
  }

}
