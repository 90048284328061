import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
/* import { BrowserModule } from '@angular/platform-browser'; */
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

// Routing

// Componentes
import { PrincipalComponent } from './components/principal/principal.component';
import { InterfazGraficaComponent } from './components/interfaz-grafica/interfaz-grafica.component';
import { MenuPrincipalComponent } from './components/menu-principal/menu-principal.component';
import { HeaderComponent } from './components/header/header.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoadingComponent } from './components/loading/loading.component';
import { BotonSubirComponent } from './components/boton-subir/boton-subir.component';
import { FlipbookComponent } from './components/flipbook/flipbook.component';

// Routing
import { InterfazGraficaRouting } from './interfaz-grafica.routing.module';

// Servicios

@NgModule({
  imports: [
    CommonModule,
    /* BrowserModule, */
    FormsModule,
    HttpModule,
    InterfazGraficaRouting
  ],
  exports: [
    PrincipalComponent,
    InterfazGraficaComponent,
    MenuPrincipalComponent,
    HeaderComponent,
    BreadcrumbComponent,
    FooterComponent,
    LoadingComponent,
    BotonSubirComponent,
    FlipbookComponent
  ],
  declarations: [
    PrincipalComponent,
    InterfazGraficaComponent,
    MenuPrincipalComponent,
    HeaderComponent,
    BreadcrumbComponent,
    FooterComponent,
    LoadingComponent,
    BotonSubirComponent,
    FlipbookComponent
  ],
  providers: [
  ]
})
export class InterfazGraficaModule { }
