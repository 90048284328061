import { NgModule } from '@angular/core';
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Conponentes
import { PrincipalComponent } from './components/principal/principal.component';
import { InterfazGraficaComponent } from './components/interfaz-grafica/interfaz-grafica.component';

// Servicios

const routes: Routes = [
    {
        path: 'interfaz-grafica',
        component: PrincipalComponent,
        children: [
            {
                path: '',
                redirectTo: 'Principal',
                pathMatch: 'full'
            },
            {
                path: 'Principal',
                component: InterfazGraficaComponent
            }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class InterfazGraficaRouting { }
