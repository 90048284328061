import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css', '../../../assets/404/css/style.css']
})
export class PageNotFoundComponent implements OnInit, OnDestroy {

  constructor(
    private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'gray-bg');
    // this.renderer.addClass(document.body, 'FondoLogin');
  }

  ngOnInit() {
    let loop1;
    let loop2;
    let loop3;
    const time = 30;
    let i = 0;
    const selector3 = document.querySelector('.thirdDigit');
    const selector2 = document.querySelector('.secondDigit');
    const selector1 = document.querySelector('.firstDigit');

    loop3 = setInterval(() => {
      if (i > 40) {
        clearInterval(loop3);
        selector3.textContent = '4';
      } else {
        selector3.textContent = this.randomNum();
        i++;
      }
    }, time);

    loop2 = setInterval(() => {
      if (i > 80) {
        clearInterval(loop2);
        selector2.textContent = '0';
      } else {
        selector2.textContent = this.randomNum();
        i++;
      }
    }, time);

    loop1 = setInterval(() => {
      if (i > 100) {
        clearInterval(loop1);
        selector1.textContent = '4';
      } else {
        selector1.textContent = this.randomNum();
        i++;
      }
    }, time);
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'gray-bg');
    // this.renderer.removeClass(document.body, 'FondoLogin');
  }

  randomNum() {
    return '' + Math.floor(Math.random() * 9) + 1;
  }
}
