import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-interfaz-grafica',
  templateUrl: './interfaz-grafica.component.html',
  styleUrls: ['./interfaz-grafica.component.css']
})

export class InterfazGraficaComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
