import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Componentes
import { MainComponent } from './components/main/main.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'inicio',
        pathMatch: 'full'
    },
    {
        path: 'inicio',
        component: MainComponent
    },
    {
        path: 'quienes-somos',
        loadChildren: './components/quienes-somos/quienes-somos.module#QuienesSomosModule'
    },
    {
        path: 'colegios/kinder',
        loadChildren: './components/kinder/kinder.module#KinderModule'
    },
    {
        path: 'colegios/comunion',
        loadChildren: './components/comunion/comunion.module#ComunionModule'
    },
    {
        path: 'colegios/promocion6to',
        loadChildren: './components/promocion6to/promocion6to.module#Promocion6toModule'
    },
    {
        path: 'colegios/confirmacion',
        loadChildren: './components/confirmacion/confirmacion.module#ConfirmacionModule'
    },
    {
        path: 'colegios/promocion5to',
        loadChildren: './components/promocion5to/promocion5to.module#Promocion5toModule'
    },
    {
        path: 'corporativos',
        loadChildren: './components/corporativos/corporativos.module#CorporativosModule'
    },
    {
        path: 'eventos/cumpleaños/matine',
        loadChildren: './components/matine/matine.module#MatineModule'
    },
    {
        path: 'eventos/cumpleaños/cumple-15',
        loadChildren: './components/cumple15/cumple15.module#Cumple15Module'
    },
    {
        path: 'eventos/matrimonios',
        loadChildren: './components/matrimonio/matrimonio.module#MatrimonioModule'
    },
    {
        path: 'drone',
        loadChildren: './components/drone/drone.module#DroneModule'
    },
    {
        path: 'foto-cabina',
        loadChildren: './components/foto-cabina/foto-cabina.module#FotoCabinaModule'
    },
    {
        path: 'productos',
        loadChildren: './components/productos/productos.module#ProductosModule'
    },
    {
        path: 'merchandising',
        loadChildren: './components/merchandising/merchandising.module#MerchandisingModule'
    },
    {
        path: 'clientes',
        loadChildren: './components/clientes/clientes.module#ClientesModule'
    },
    {
        path: 'contactenos',
        loadChildren: './components/contactenos/contactenos.module#ContactenosModule'
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule { }
