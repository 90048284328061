interface Scripts {
    name: string;
    src: string;
}

export const ScriptStore: Scripts[] = [
    {
        name: 'googleapis.maps',
        src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCbeBYsZSDkbIyfUkoIw1Rt38eRQOQQU0o'
    },
    {
        name: 'scripts',
        src: 'assets/js/scripts.js'
    },
    {
        name: 'slicknav_btn',
        src: 'assets/js/jquery.slicknav.min.js'
    }
];
