import { Component, OnInit } from '@angular/core';

// Servicios
import { GlobalService } from '../../../../services/global.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    public _globalService: GlobalService
  ) { }

  ngOnInit() {
  }

}
