import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs, 'es');

// Modulos
import { InterfazGraficaModule } from './modules/interfaz-grafica/interfaz-grafica.module';

// Componentes
import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

// Servicios
import { GlobalService } from './services/global.service';
import { ScriptService } from './services/script.service';
import { RuteoGuard } from './services/ruteo.guard';

// Routing
import { AppRoutingModule } from './app.routing';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    InterfazGraficaModule
  ],
  exports: [
    InterfazGraficaModule,
    MainComponent,
    PageNotFoundComponent
  ],
  declarations: [
    AppComponent,
    MainComponent,
    PageNotFoundComponent
  ],
  providers: [
    GlobalService,
    ScriptService,
    RuteoGuard
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
