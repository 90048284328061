import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// Servicios
import { ScriptService } from './script.service';

@Injectable()
export class RuteoGuard implements CanActivate {
    public urlApi: string;

    constructor(
        private _scriptService: ScriptService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        switch (state.url) {
            case '/contactenos':
                /* this._scriptService.load('scripts').then(data => {
                }).catch(error => console.log(error)); */
                break;
            default:
                /* this._scriptService.load('slicknav_btn').then(data => {
                    $('ul#navigation').slicknav({
                        prependTo: '.responsive-menu-wrap'
                    });

                    $('.slicknav_btn').attr('href', 'javascript:void(0);');
                }).catch(error => console.log(error)); */
                break;
        }

        return true;
    }
}
