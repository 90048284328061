import { Component, OnInit, DoCheck } from '@angular/core';

// Servicios

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css'],
  providers: [
  ]
})
export class BreadcrumbComponent implements OnInit/* , DoCheck */ {
  public menu;

  constructor(
  ) { }

  ngOnInit() {
  }
}
